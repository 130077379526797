@import "../../../node_modules/jquery-ui/themes/base/base.css";
@import '../load.scss';
.ui{
    &-widget-content{
    background-color: aliceblue;
    box-shadow:1px 5px 2px 1px rgba(17, 17, 17, 0.2);
    }
    &-autocomplete {
        max-height: 150px;
        overflow-y: auto;
        /* prevent horizontal scrollbar */
        overflow-x: hidden;
      }
}
